<template>
  <div class="row">
    <div class="col-12 col-lg-8 c">
      <div class="card">
        <div class="card-header">
          <h4>
            المناوبة والاشراف
          </h4>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered">
                    <thead>
                        <th>
                            اليوم
                        </th>
                        <th>
                            المعلمين
                        </th>
                        <th>
                            اضافة معلم
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="d in days" :key="d">
                            <td>
                                {{ dayname(d) }}
                            </td>
                            <td>
                                <ul>
                                    <li v-for="t in shifts[d]" :key="t">{{ t }} <u class="text-danger" @click="deleteMe(d, t)">حذف</u></li>
                                </ul>
                            </td>
                            <td>
                                <select v-model="addme" @change="addMeNow(d)">
                                    <option value="">-- اختر للاضافة --</option>
                                    <option v-for="t in teachers" :key="t.name" :value="t.name">
                                        {{ t.name }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
const axios = require("axios");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
        teachers: [],
        shifts: {},
        loading: true,
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday"],
        addme: ""
    };
  },
  methods: {
    deleteMe(d, t){
        var g = this, arr = [];
        this.shifts[d].forEach(function(a){
            if(a != t){
                arr.push(a)
            }
        })
        this.shifts[d] = JSON.parse(JSON.stringify(arr))
        this.shifts = JSON.parse(JSON.stringify(this.shifts))
        g.save()
    },
    addMeNow(d){
        this.shifts[d].push(this.addme)
        this.addme = ""
        this.save()
    },
    dayname(d){
        d = d.replace("Sunday", "الاحد");
        d = d.replace("Monday", "الاثنين");
        d = d.replace("Tuesday", "الثلاثاء");
        d = d.replace("Wednesday", "الاربعاء");
        d = d.replace("Thursday", "الخميس");
        d = d.replace("Friday", "الجمعة");
        d = d.replace("Saturday", "السبت");
        return d;
    },
    save(){
        var _g = this;
        axios
        .post(localStorage.getItem("api") + "/teachers/shifts/save", {
            jwt: localStorage.getItem("jwt"),
            shifts: JSON.stringify(_g.shifts)
        })
        .then(function (r) {
            if (r.data.status == 200) {
            _g.$toast({
                component: ToastificationContent,
                props: {
                title: "حدث خطأ JWT",
                icon: "WarningIcon",
                variant: "danger",
                },
            });
            } else {
            _g.loading = false;
            }
        })
        .catch(function () {
            _g.$toast({
            component: ToastificationContent,
            props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
            },
            });
        });
    }
  },
  created() {
    var _g = this;
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    days.forEach(function(a){
        _g.shifts[a] = [];
    })
    axios
    .post(localStorage.getItem("api") + "/teachers/list", {
        jwt: localStorage.getItem("jwt"),
    })
    .then(function (r) {
        if (r.data.status == 200) {
        _g.$toast({
            component: ToastificationContent,
            props: {
            title: "حدث خطأ JWT",
            icon: "WarningIcon",
            variant: "danger",
            },
        });
        } else {
        _g.teachers = r.data.response;
        _g.loading = false;
        }
    })
    .catch(function () {
        _g.$toast({
        component: ToastificationContent,
        props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
        },
        });
    });
    axios
      .post(localStorage.getItem("api") + "/teachers/shifts", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          try{
            if(r.data.response){
                _g.shifts = JSON.parse(r.data.response);
            }
          }catch{
            days.forEach(function(a){
                _g.shifts[a] = [];
            })
          }
          _g.loading = false;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
